var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"overflow-y-auto overflow-x-hidden",attrs:{"flat":"","tile":"","height":"calc(100vh - 330px)"}},_vm._l((_vm.types),function(type){return _c('v-container',{key:type,attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.cardClass === product.id)?_c('v-img',{class:_vm.cardClass === product.id ? 'loader' : '',attrs:{"transition":"scroll-y-transition","src":product.img
                ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                  product.img
                : '/burger.png',"aspect-ratio":"1.3"}}):_vm._e()],1),_c('v-card',{attrs:{"flat":""},on:{"click":function($event){return _vm.productToCart(product)}}},[_c('v-img',{attrs:{"transition":"scroll-y-transition","src":product.img
                ? 'https://infiniti-kiosk-files-ekspil.amvera.io/api/v1/files/download/1/' +
                  product.img
                : '/burger.png',"aspect-ratio":"1.3"}}),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.getNameByLang(product)))])]),_c('v-card-title',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"text-subtitle-1 ma-auto"},[_vm._v(_vm._s(product.price)+" р.")])])],1)],1)}),1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }